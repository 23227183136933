@media (min-width:992px){
    ul.menu-nav .bottom-menu-item .menu-submenu{
        display: block;
        -webkit-animation: header-menu-submenu-fade-in .3s ease 1,header-menu-submenu-move-down .3s ease-out 1;
        animation: header-menu-submenu-fade-in .3s ease 1,header-menu-submenu-move-down .3s ease-out 1;
        padding:20px 0;
        background-color: #fff;
        box-shadow: 0 15px 50px 0 rgba(82,63,105,.15);
        width: 275px;
        margin: 0 auto;
        border-radius: 4px;
        z-index: 98;
        position: absolute;
        top: 100%;
        right: auto;
        left: 0;
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d;
        
    }

    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav{
        list-style: none!important;
        padding: 0;
        margin: 0;
    }
    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav .menu-item{
        position: relative;
        padding: 0;
        margin: 0;  
    }
    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav .menu-item .menu-link{
        transition: all .3s ease;
        cursor: pointer;
        padding: 11px 30px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        text-decoration: none;
        position: relative;
        vertical-align: middle;
    }
    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav .menu-item .menu-link .menu-arrow {
        font-size: .6rem;
        width: 20px;
        justify-content: flex-end;
        padding: 0 0 0 10px;
        display: flex;
        align-items: center;
    }

    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav .menu-item .menu-link .menu-icon.svg-icon {
        height: 23px;
        width: 23px;
        margin-left: -2px;
        color: #b5b5c3;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        flex: 0 0 33px;
        padding: 0;
        line-height: 0;
    }
    ul.menu-nav .bottom-menu-item .menu-submenu .menu-subnav .menu-item .menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: none;
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0;
    }


    /* Sub-sub Menu */
    ul.menu-nav .bottom-menu-item .menu-submenu>.menu-submenu {
        top: 0;
        display: none;
        margin-top: 0;
    }
}