//
// Header Menu
//

// Desktop Mode
@include menu-hor-build-layout(get($header-menu-config, desktop));
@include menu-hor-build-theme(get($header-menu-config, desktop), default);

// Tablet & Mobile Mode
@include menu-ver-build-layout(get($header-menu-config, tablet-and-mobile));
@include menu-ver-build-theme(get($header-menu-config, tablet-and-mobile), default);

// Header Menu Mobile Offcanvas
@include offcanvas-build(header-menu-wrapper, tablet-and-mobile, get($header-menu-config, offcanvas-mobile));

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Header Menu Wrapper
	.header-menu-wrapper {
		display: flex;
		align-items: stretch;
		align-self: flex-end;
	}

	// Header Menu
	.header-menu {
		display: flex;
		align-items: stretch;

		// Header Nav
		.menu-nav {
			display: flex;
			align-items: stretch;

			> .menu-item {
				&:first-child {
					padding-left: 0;
				}

				> .menu-link {
					@include border-radius($border-radius $border-radius 0 0);
					padding: 0.75rem 1.25rem;

					.menu-text {
						color: $white;
						font-weight: 500;
						font-size: 1.09rem;
						// opacity: 0.8;
					}

					.menu-arrow {
						color: rgba($white, 0.8);
						opacity: 0.8;
					}

					.menu-icon {
						i {
							color: rgba($white, 0.8);
							opacity: 0.8;
						}

						.svg-icon {
							@include svg-icon-color(rgba($white, 0.8));
						}
					}
				}

				// Active & Hover States
				&:hover:not(.menu-item-here):not(.menu-item-active){
					> .menu-link {
						@include border-radius($border-radius $border-radius 0 0);
						padding: 0.75rem 1.25rem;
	
						.menu-text {
							color: $white;
							font-weight: 500;
							opacity: 1;
						}
	
						.menu-arrow {
							color: rgba($white, 0.8);
							opacity: 1;
						}
	
						.menu-icon {
							i {
								color: rgba($white, 0.8);
								opacity: 1;
							}
	
							.svg-icon {
								@include svg-icon-color(rgba($white, 0.8));
							}
						}
					}
				}
				&.menu-item-hover:not(.menu-item-here):not(.menu-item-active),
				&.menu-item-here,
				&.menu-item-active {
					> .menu-link {
						background-color: $white;
						border-color: #E4E6EF #E4E6EF #ffffff;
						.menu-text {
							color: #000000;
						}

						.menu-arrow {
							color: #5E6278
						}

						.menu-icon {
							i {
								color: #5E6278
							}

							.svg-icon {
								@include svg-icon-color(#5E6278);
							}
						}
					}
				}
			}
// Bottom Menu items
			> .bottom-menu-item {
			
				> .bottom-menu-link {
					@include border-radius($border-radius);
					padding: 0.75rem 1.25rem;

					.bottom-menu-text {
						color: #000000;
						font-weight: 500;
						// opacity: 0.8;
						font-size: 1.09rem;
					}

					.bottom-menu-arrow {
						color: #000000;
						// opacity: 0.8;
					}

					.bottom-menu-icon {
						i {
							color: #7E8299;
							opacity: 0.8;
						}

						.svg-icon {
							@include svg-icon-color(rgba(#7E8299, 0.8));
						}
					}
				}

				// Active & Hover States
				&:hover:not(.bottom-menu-item-here):not(.bottom-menu-item-active){
					> .bottom-menu-link {
						@include border-radius($border-radius);
						padding: 0.75rem 1.25rem;
	
						.bottom-menu-text {
							color: #3699FF;
							font-weight: 500;
							opacity: 1;
							font-size: 1.09rem;
						}
	
						.bottom-menu-arrow {
							color: #3699FF;
							opacity: 1;
						}
	
						.bottom-menu-icon {
							i {
								color: #3699FF;
								opacity: 1;
							}
	
							.svg-icon {
								@include svg-icon-color(rgba(#3699FF, 0.8));
							}
						}
					}
				}
				&.bottom-menu-item-hover:not(.bottom-menu-item-here):not(.bottom-menu-item-active),
				&.bottom-menu-item-here,
				&.bottom-menu-item-active {
					> .bottom-menu-link {
						background-color: #F3F6F9;
						border-color: #E4E6EF #E4E6EF #ffffff;
						.bottom-menu-text {
							color: #3699FF;
							font-size: 1.09rem;
						}

						.bottom-menu-arrow {
							color: #3699FF
						}

						.bottom-menu-icon {
							i {
								color: #3699FF
							}

							.svg-icon {
								@include svg-icon-color(#3699FF);
							}
						}
					}
				}
			}
		}

		// Fixed Header & Header Scroll Modes
		.header-fixed[data-header-scroll="on"] & {

			.header-menu-wrapper {
				align-self: center;
			}
			// Header Nav
			.menu-nav {
				> .menu-item {
					> .menu-link {
						@include border-radius($border-radius);
						padding: 0.75rem 1.25rem;
	
						.menu-text {
							color: $white;
						}
					}

					// Active & hover states
					&:hover:not(.menu-item-here):not(.menu-item-active),
					&.menu-item-hover:not(.menu-item-here):not(.menu-item-active),
					&.menu-item-here,
					&.menu-item-active {
						> .menu-link {
							background-color: $white;
							border-color: #E4E6EF #E4E6EF #ffffff;
							.menu-text {
								color: #5E6278;
							}
	
							.menu-arrow {
								color: #5E6278
							}
	
							.menu-icon {
								i {
									color: #5E6278
								}
	
								.svg-icon {
									@include svg-icon-color(#5E6278);
								}
							}
						}
					}
				}
			}
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Header Wrapper
	.header-menu-wrapper {
		// Logo
		.header-logo {
			display: none;
		}
	}
}
