// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

@import './variables.scss';

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.w-80 {
	width: 60% !important;
	@media (min-width: 768px) {
		width: 80% !important;
	}
}
.w-40 {
	width: 100% !important;
	@media (min-width: 768px) {
		width: 40% !important;
	}
}
.w-20 {
	width: 20%;
	@media (min-width: 768px) {
		width: 10% !important;
	}
}
.height200 {
	height: 200px;
}

.FilterBtn {
	@media (max-width: 767px) {
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: 20px !important;
	}
}
.symbol.custom-symbol-45 > .symbol-label {
	min-width: 30px;
	padding: 0 10px;
	width: auto;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}

// Tables
.bannerTable {
	thead {
		th {
			vertical-align: middle;
			&:nth-of-type(3) {
				width: 100px;
			}
			&:nth-of-type(4),
			&:nth-of-type(5) {
				width: 200px;
			}
		}
	}
}

.orderListTable,
.employeesTable {
	thead {
		th {
			&:nth-of-type(2) {
				width: 10% !important;
			}
		}
	}
}

.faqTable {
	thead {
		th {
			&:nth-of-type(3) {
				width: 50%;
			}
		}
	}
}

.text-muted {
	color: #191919 !important;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
	color: #010101 !important;
}
.table thead th:nth-of-type(2) {
	width: 20%;
}

.form-group label {
	font-size: 1.1rem;
}

// Tabs
.nav-tabs .nav-link {
	color: #010101;
	&.active {
		box-shadow: 0 0 10px #ccc;
	}
}

// MyForm1 css
.form-group label {
	color: #000000;
}
#myForm1 {
	.MuiOutlinedInput-input {
		padding: 14px;
	}
	.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
		.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
		padding: 0 14px;
	}
	.input-group {
		.form-control {
			border-color: #ccc;
			border-radius: 0 0.42rem 0.42rem 0;
		}
		// .react-datepicker-wrapper{
		//   width:87%;
		// }
		&.myDateTime {
			.input-group-prepend {
				width: 13%;
			}
			.react-datepicker-wrapper {
				width: 85%;
			}
		}
		&.myDateTime2 {
			.input-group-prepend {
				width: 5%;
			}
			.react-datepicker-wrapper {
				width: 95%;
			}
		}
		.input-group-prepend {
			.input-group-text {
				background-color: #ffffff;
				border-color: #ccc;
				padding: 6px 12px;
				.fas,
				.fa {
					color: #010101;
				}
			}
		}
	}
	.form-control {
		border-color: #ccc;
	}
}

// Working Hours
.workingSchedule {
	.form-check {
		padding: 0.8rem 1.25rem;
	}
}

// Expansion Panels
.myExpansion {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	&:hover {
		background-color: #f3f6f9;
		border-radius: 0.42rem;
		.MuiExpansionPanelSummary-content {
			p {
				transition: all 0.15s ease;
				color: $expansionHoverLinkColor;
			}
		}
	}
	.MuiExpansionPanelSummary-content {
		p {
			font-size: 1rem;
			font-weight: 500;
			color: $expansionLinkColor;
			transition: all 0.15s ease;
			flex-grow: 1;
			margin-bottom: 0;
		}
	}
	.MuiCollapse-container {
		.menu-submenu {
			.menu-subnav {
				a.menu-link {
					color: $expansionLinkColor;
					&:hover,
					&.active {
						color: $expansionHoverLinkColor;
					}
				}
			}
		}
	}
}

.faqPanels {
	#panel1bh-header {
		.faqId {
			flex-basis: 5%;
		}
		.faqQuestion {
			color: #000000;
			font-family: inherit;
			font-size: 1rem;
		}
	}
}

// Image Crop View
.myLabel {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
}
.fileUploadContainer {
	position: relative;
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		opacity: 0;
		cursor: pointer;
	}
}

// Order Page
.cartBtns {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	padding: 0;
	svg {
		rect {
			fill: #fff;
		}
	}
}
.cartQuantity {
	margin: 0 10px;
	width: 60px;
	border: 1px solid #e4e6ef;
	border-radius: 5px;
	padding: 5px;
}

// Dashboard Filters
.myForms {
	form {
		margin-bottom: 20px;
	}
}
.fileinput {
	&.text-center {
		text-align: center;
	}
	.fa {
		font-size: 14px;
		margin-top: -6px;
		position: relative;
		top: 2px;
		margin-right: 4px;
	}
	display: inline-block;
	margin-bottom: 9px;

	input[type='file'] {
		display: none;
	}
}

.deleteIcon {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 9;
}
